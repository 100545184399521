import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import type { FC } from 'react';
import type { UserObject } from '../../entities';
import MemberRegistrationPrompt from './MemberRegistrationPrompt';
import { generateDialogTitle } from 'src/helpers/userRegistrationHelpers';

export interface Props {
  onClose?: () => void;
  open: boolean;
  user: UserObject | null;
  isSimpleForm?: boolean;
}

const SignupDialog: FC<Props> = ({ onClose = () => {}, open, user, isSimpleForm = false }) => {
  const dialogTitle = generateDialogTitle(user);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: '#fff',
        },
      }}
    >
      <DialogTitle>{dialogTitle}</DialogTitle>
      <DialogContent sx={{ mx: 0, px: 1 }}>
        <MemberRegistrationPrompt user={user} isSimpleForm={isSimpleForm} />
      </DialogContent>
    </Dialog>
  );
};

export default SignupDialog;
