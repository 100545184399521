export const prefix = `${(process.env.NEXT_PUBLIC_ASSET_PREFIX || '').replace(/\/$/, '')}/`;
export const contentfulPrefix = `${(process.env.NEXT_PUBLIC_CONTENTFUL_ASSET_PREFIX || '').replace(/\/$/, '')}/`;

export const contentfulAssetURL = (url: string) => {
  if (contentfulPrefix === '/') {
    if (url.indexOf('//') === 0) {
      return `https:${url}`;
    }
    return url;
  }

  return url.replace(/^\/\/(image|asset|download)s\.ctfassets\.net/, contentfulPrefix);
};

export const s3AssetURL = (path: string) => {
  const prefix = (process.env.NEXT_PUBLIC_S3_ASSET_PREFIX || '').replace(/\/$/, '');
  return `${prefix}/${path.replace(/^\//, '')}`;
};

export const curriculumImageURL = (slug: string) => {
  return s3AssetURL(`/curriculum-images/${slug}-top.png`);
};
