import { Box, Typography, Button, Stack } from '@mui/material';
import type { FC } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import type { UserObject } from '../../entities';
import SignupForm from '../forms/SignupForm';
import { skipRegisterSteps } from 'src/clients/api';
import { isOnlyStep1Completed } from 'src/helpers/userRegistrationHelpers';

interface Props {
  user: UserObject | null;
  isSimpleForm?: boolean;
}

const MemberRegistrationPrompt: FC<Props> = ({ user, isSimpleForm }) => {
  const router = useRouter();

  const onSkipSubmit = async () => {
    await skipRegisterSteps();
    router.reload();
  };

  if (!user) {
    return (
      <Box>
        <Box sx={{ padding: '0 24px', fontSize: '0.85em', color: 'text.secondary' }}>
          口コミアカデミーは店舗をお持ちの店長様、スタッフの方々に向けたサービスです。
          <br />
          コンテンツの閲覧には会員登録が必要となります。
        </Box>
        <SignupForm isSimpleForm={isSimpleForm} />
      </Box>
    );
  }

  if (isOnlyStep1Completed(user)) {
    return (
      <Box sx={{ p: 2 }}>
        <Stack spacing={2}>
          <NextLink href="/account/signup/" passHref>
            <Button variant="contained" size="large" fullWidth component="a">
              アンケートに回答する
            </Button>
          </NextLink>
          <Button variant="contained" size="large" fullWidth component="a" onClick={onSkipSubmit}>
            コンテンツを今すぐみる
          </Button>
        </Stack>
      </Box>
    );
  }

  if (!user.filledAll) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="h6" align="center" color="text.primary" gutterBottom sx={{ mb: 2 }}>
          会員登録を完了させてください
        </Typography>
        <NextLink href="/account/signup/" passHref>
          <Button variant="contained" size="large" fullWidth component="a">
            会員登録に進む
          </Button>
        </NextLink>
      </Box>
    );
  }

  return null;
};

export default MemberRegistrationPrompt;
