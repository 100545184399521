import type { UserObject } from '../entities';

export type HTTPMethod = 'POST' | 'DELETE' | 'PUT' | 'GET';

export type Links = ([string, string] | [string])[];

export interface UserPageProps {
  user: UserObject | null;
}

export interface ErrorResponse {
  error: string;
}

export enum ActivityType {
  LessonStart = 'lesson.start',
  LessonEnd = 'lesson.end',
  WhitepaperDownload = 'whitepaper.download',
  SeminarRequest = 'seminar.request',
  SeminarAttend = 'seminar.attend',
  SeminarView = 'seminar.view',
  CaseDownload = 'case.download',
}

export type ArticleType = 'whitepaper' | 'seminar' | 'case';

export interface PageInfo {
  current: number;
  total: number;
  perPage: number;
}

export interface SectionAchievePoint {
  sectionElementaryThird: number | null;
  sectionElementaryHalf: number | null;
  sectionElementaryLast: number | null;
  sectionIntermediateThird: number | null;
  sectionIntermediateHalf: number | null;
  sectionIntermediateLast: number | null;
}

export interface CurriculmSectionCount {
  title: string;
  index: number;
  total: number;
  completed: number;
  progress: number;
}

export type ExternalIdAuthType = 'google';

export type RegistrationStatusType =
  | 'basicInfoRegistered'
  | 'webMediaAdoptionsRegistered'
  | 'webMediaInterestsRegistered';

export type SeminarType = 'live' | 'onDemand';

export const InquiryType = {
  Large: 'large',
  FoodRetail: 'food_retail',
  Other: 'other',
  New: 'new',
} as const;

export type InquiryType = typeof InquiryType[keyof typeof InquiryType];

export * from './RegistrationStep';
