import { Box, Dialog, DialogContent, DialogTitle, IconButton, useMediaQuery, useTheme } from '@mui/material';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useLayoutEffect, useState } from 'react';
import type { UserObject } from '../../entities';
import type { SectionAchievePoint } from '../../types';
import MemberRegistrationPrompt from '../global/MemberRegistrationPrompt';
import { Close } from '../../graphics';
import { useCurriculumContext } from 'src/contexts/CurriculumContext';
import { isMemberRegistrationIncomplete } from 'src/helpers/userRegistrationHelpers';

interface Props {
  lessonId: string | null;
  user: UserObject | null;
  onClose?: () => void;
  onNext?: (lessonId: string) => void;
  handleAchieveDialog?: (handle: boolean, nextLesson: string, achievePoint: string) => void;
  sectionAchievePoint?: SectionAchievePoint;
  sectionInbound?: boolean;
}

const DynamicLessonChat = dynamic(() => import('./LessonChat').then((mod) => mod.default), { ssr: false });

const LessonChatDialog: React.FC<Props> = ({
  lessonId,
  onClose,
  user,
  onNext,
  handleAchieveDialog,
  sectionAchievePoint,
  sectionInbound,
}) => {
  const theme = useTheme();
  const router = useRouter();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // NOTE:
  // マイページの教科書の進み具合から開く場合は Provider を使っていないので Context は取得できないが、
  // curriculum の値は非会員向けの isFirstLesson 判定のためのものなのでログイン後は現状必要ない
  const curriculum = useCurriculumContext();
  // NOTE:
  // const isFirstLesson = curriculum?... と直接定義すると、このモーダルが閉じる直前に
  // isFirstLesson が更新され、!isFirstLesson 条件の内容がちらつくので、これを防ぐために useState で定義している
  const [isFirstLesson, setIsFirstLesson] = useState(curriculum?.sections[0]?.lessons[0]?.id === lessonId);
  useLayoutEffect(() => {
    if (lessonId) {
      setIsFirstLesson(curriculum?.sections[0]?.lessons[0]?.id === lessonId);
    }
  }, [lessonId, curriculum]);

  // 会員登録未完了かつ2ページ目以降の場合は会員登録導線を表示する
  const shouldShowMemberRegistrationPrompt = isMemberRegistrationIncomplete(user) && !isFirstLesson;

  const handleClose = () => {
    // 会員登録導線を表示する場合は escape キーやダイアログ外をクリックしても閉じないようにする
    !shouldShowMemberRegistrationPrompt && onClose?.();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      open={!!lessonId}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        style: {
          backgroundColor: '#fff',
        },
      }}
    >
      {shouldShowMemberRegistrationPrompt ? (
        <>
          <DialogTitle>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Box sx={{ flex: '1', display: 'flex', flexDirection: 'column' }}>2ページ目以降は会員登録が必要です</Box>
              <Box>
                <IconButton size="small" onClick={onClose} sx={{ ml: 1 }}>
                  <Close />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ mx: 0, px: 1 }}>
            <MemberRegistrationPrompt user={user} />
          </DialogContent>
        </>
      ) : (
        <DialogContent sx={{ mx: 0, px: 1 }}>
          <DynamicLessonChat
            lessonId={lessonId || ''}
            user={user}
            debug={router.query.debug === '1'}
            onClose={onClose}
            onNext={onNext}
            handleAchieveDialog={handleAchieveDialog}
            sectionAchievePoint={sectionAchievePoint}
            sectionInbound={sectionInbound}
          />
        </DialogContent>
      )}
    </Dialog>
  );
};
export default LessonChatDialog;
