import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

interface Props {
  lessonId?: string;
  onClose?: () => void;
}

const useLesson = ({ lessonId = '', onClose = () => {} }: Props = {}) => {
  const router = useRouter();
  const [selectedLessonId, setSelectedLessonId] = useState<string | null>(null);
  const { lessonId: currentLessonId } = router.query as { lessonId: string };

  const link = `${router.asPath.replace(/\?.*/, '')}?lessonId=${lessonId}`;

  useEffect(() => {
    if ((!currentLessonId || currentLessonId !== lessonId) && selectedLessonId === lessonId) {
      onClose();
    }
    setSelectedLessonId(currentLessonId);
  }, [currentLessonId, lessonId, onClose, selectedLessonId]);

  const push = (lessonId: string | null) => {
    const newPath = `${router.asPath.replace(/\?.*/, '')}${lessonId ? `?lessonId=${lessonId}` : ''}`;
    if (router.asPath !== newPath) {
      // NOTE: コード的には shallow: true にしても動きそうだが、chrome のみ発生する不具合があったため様子 shallow: false にしている
      // https://www.notion.so/movinc/856aaf508f6f4c3cafc0e22568466a12?pvs=4
      router.push(newPath, undefined);
    }
  };

  return { link, currentLessonId, push };
};

export default useLesson;
