import styled from '@emotion/styled';
import {
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Toolbar,
} from '@mui/material';
import type { FC } from 'react';
import { useState } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import type { UserObject } from '../../entities/User';
import { Logo, Humburger, Close, ChevronRight } from '../../graphics';
import { sitePages } from '../../config/links';
import * as pageTitles from '../../config/pageTitles';

interface Props {
  user: UserObject | null;
  isLoadingUser: boolean;
}

const Header: FC<Props> = ({ user, isLoadingUser }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const router = useRouter();
  const { pathname } = router;

  return (
    <Wrapper>
      <Line1>
        <Container sx={{ display: 'flex', alignItems: 'center', height: '46px' }}>
          <Box sx={{ fontSize: '0.8em', flexGrow: 1 }}>仕込みの合間に通うお店の学校</Box>
          {!isLoadingUser && pathname !== '/thanks' && (
            <Box>
              {user ? (
                <NextLink href="/account" passHref>
                  <Button color="secondary" variant="text" component="a">
                    マイページ
                  </Button>
                </NextLink>
              ) : (
                <>
                  <NextLink href="/login" passHref>
                    <Button color="secondary" variant="text" component="a">
                      ログイン
                    </Button>
                  </NextLink>
                  <NextLink href="/" passHref>
                    <SignupButton
                      variant="contained"
                      color="secondary"
                      component="a"
                      sx={{ display: { md: 'inline-block', xs: 'none' } }}
                    >
                      新規登録
                    </SignupButton>
                  </NextLink>
                </>
              )}
            </Box>
          )}
        </Container>
      </Line1>
      <Line2>
        <Container>
          <Toolbar disableGutters sx={{ gap: 3 }}>
            <Box sx={{ flexGrow: 1, height: { md: '77px', xs: '44px' }, padding: { md: '20px 0', xs: '10px 0' } }}>
              <LogoWrapper>
                <NextLink href="/" passHref>
                  <a>
                    <Logo />
                  </a>
                </NextLink>
              </LogoWrapper>
            </Box>
            <Box sx={{ py: 2, flexGrow: 0, gap: 4, display: { md: 'flex', xs: 'none' } }}>
              {sitePages.map(([label, href, icon]) => (
                <Box
                  key={href}
                  sx={{
                    p: 0,
                    m: 0,
                    borderBottom: pathname.indexOf(href.replace(/\/$/, '')) === 0 ? '2px solid #000' : undefined,
                  }}
                >
                  <NextLink href={href} passHref key={href}>
                    <Anchor>
                      <Box sx={{
                        fontSize: '12px',
                        letterSpacing: '1.2px',
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        '&:before': {
                          content: `url(${icon})`,
                        }
                      }}>
                        <span>{label}</span>
                      </Box>
                    </Anchor>
                  </NextLink>
                </Box>
              ))}
            </Box>
            <IconButton
              sx={{ flexGrow: 0, display: { md: 'none', xs: 'flex' } }}
              onClick={() => setIsDrawerOpen(!isDrawerOpen)}
            >
              {isDrawerOpen ? <Close /> : <Humburger />}
            </IconButton>
          </Toolbar>
        </Container>
      </Line2>
      <Drawer anchor="top" open={isDrawerOpen} sx={{ zIndex: 1100 }} onClose={() => setIsDrawerOpen(false)}>
        <DrawerInner>
          {!user && (
            <Stack spacing={1} sx={{ paddingBottom: '20px' }}>
              <NextLink href="/login" passHref>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  component="a"
                  sx={{ border: '2px solid #FFD900', fontWeight: 700 }}
                >
                  ログイン
                </Button>
              </NextLink>
              <NextLink href="/" passHref>
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  component="a"
                  onClick={() => setIsDrawerOpen(false)}
                  sx={{ fontWeight: 700 }}
                >
                  無料で会員登録
                </Button>
              </NextLink>
            </Stack>
          )}
          <List component="div">
            {sitePages.map(([label, href, icon], i) => (
              <NextLink href={href} passHref key={href}>
                <ListItem
                  divider={i < sitePages.length - 1 || !!user}
                  secondaryAction={<ChevronRight />}
                  button
                  component="a"
                  sx={{ padding: '15px' }}
                >
                  <Box sx={{
                    display: 'flex',
                    marginRight: '16px',
                    '&:before': {
                      content: `url(${icon})`,
                      marginRight: '8px',
                    }
                  }}>
                    <ListItemText>{label}</ListItemText>
                  </Box>
                </ListItem>
              </NextLink>
            ))}
            {user && (
              <NextLink href="/account" passHref>
                <ListItem secondaryAction={<ChevronRight />} button component="a" sx={{ padding: '15px' }}>
                  <ListItemText>{pageTitles.myPage}</ListItemText>
                </ListItem>
              </NextLink>
            )}
          </List>
        </DrawerInner>
      </Drawer>
    </Wrapper>
  );
};

export default Header;

const Wrapper = styled.header`
  z-index: 1200;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #ffffff;
`;

const DrawerInner = styled.div`
  padding: 120px 10px 10px 10px;
  background-color: #f9f9f9;
`;

const Line1 = styled.div`
  background-color: #ffd900;
`;

const Line2 = styled.div`
  background-color: #ffffff;
`;

const LogoWrapper = styled.div`
  height: 100%;
  svg {
    height: 100%;
  }
`;

const SignupButton = styled(Button<'a'>)`
  font-weight: 700;
  border-radius: 20px;
  padding: 3px 20px;
`;

const Anchor = styled.a`
  text-decoration: none;
  color: #000;
  transition: opacity 0.1s ease-in-out;
  &:hover {
    opacity: 0.65
  }
`;