export enum RegistrationStep {
  Basic,
  WebMediaAdoptions,
  WebMediaInterests,
}

export const allRegistrationSteps = [
  RegistrationStep.Basic,
  RegistrationStep.WebMediaAdoptions,
  RegistrationStep.WebMediaInterests,
];
