import styled from '@emotion/styled';
import type { TextFieldProps } from '@mui/material';
import { Button, Checkbox, FormControlLabel, Link, MenuItem, Stack, TextField, Typography } from '@mui/material';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { getBusinessScales, signup } from '../../clients/api';
import { getFirstTouchURL, getLastTouchURL } from '../../config/cookie';
import { isValidPhoneNumber } from '../../helpers/formValidations';
import SecretInput from './SecretInput';
// import GoogleLoginForm from './GoogleLoginForm';
import type { ShortFieldName } from './AccountForm/types';
import IndustriesSelect from './IndustriesSelect';
import type { BusinessScaleObject } from 'src/entities';

interface FormValues {
  email: string;
  password: string;
  name: string;
  companyName: string;
  departmentName: string;
  personalPhone: string;
  storeName: string;
  agreement: boolean;
  industryIds: string[];
  businessScaleId: string;
  isSimpleForm: boolean;
}

interface Props {
  size?: TextFieldProps['size'];
  submitText?: string;
  onComplete?: () => Promise<void>;
  isSimpleForm?: boolean;
}

const SignupForm: FC<Props> = ({ size, submitText, onComplete, isSimpleForm }) => {
  const router = useRouter();
  const query = { path: router.asPath };

  const [businessScales, setBusinessScales] = useState<BusinessScaleObject[]>([]);

  useEffect(() => {
    const fetch = async () => {
      setBusinessScales(await getBusinessScales());
    };

    fetch();
  }, []);

  const [errorMessage, setErrorMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    // watch,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      agreement: false,
      email: '',
      password: '',
      name: '',
      companyName: '',
      departmentName: '',
      personalPhone: '',
      storeName: '',
      industryIds: [],
      businessScaleId: '',
      isSimpleForm: isSimpleForm,
    },
  });
  const onSubmit = async ({ agreement, ...data }: FormValues) => {
    if (!agreement) {
      setErrorMessage('利用規約・プライバシーポリシーへの同意をお願いします');
      return;
    }
    setErrorMessage('');
    const res = await signup({
      ...data,
      firstTouchURL: getFirstTouchURL(),
      lastTouchURL: getLastTouchURL(),
    });
    if (res.success) {
      await onComplete?.();
      setSuccess(true);
    } else {
      setErrorMessage(res.error || '会員登録に失敗しました。');
    }
  };

  if (success) {
    if (isSimpleForm) {
      router.push('/account/signup/?pages=1');
    } else {
      router.push('/account/signup/?pages=2');
    }
    return null;
  }

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Stack spacing={2}>
          {!isSimpleForm && (
            <>
              {(
                [
                  ['name', '姓名'],
                  ['companyName', '会社名・屋号'],
                  ['departmentName', '部署'],
                ] as [ShortFieldName, string][]
              ).map(([name, label]) => (
                <Controller
                  key={name}
                  control={control}
                  name={name}
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <TextField
                      variant="outlined"
                      type="text"
                      id={`input-${name.replace('Name', '-name')}`}
                      fullWidth={true}
                      required={true}
                      error={!!fieldState.error}
                      helperText={fieldState.error && `${label}を入力してください`}
                      label={label}
                      {...field}
                    />
                  )}
                />
              ))}
              <Controller
                control={control}
                name="personalPhone"
                rules={{
                  required: true,
                  validate: (value) => isValidPhoneNumber(value),
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    variant="outlined"
                    type="text"
                    id="input-personal-phone"
                    fullWidth={true}
                    required={true}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message || '電話番号を入力してください' : null}
                    label="電話番号"
                    inputProps={{
                      inputMode: 'tel',
                    }}
                    InputProps={{
                      type: 'tel',
                    }}
                    {...field}
                  />
                )}
              />
              <Controller
                control={control}
                name="industryIds"
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <IndustriesSelect
                    {...field}
                    fullWidth={true}
                    error={!!fieldState.error}
                    helperText={
                      fieldState.error ? fieldState.error.message || 'お店の業種を選択してください' : '最大3つ選択可能'
                    }
                    label="お店の業種を教えて下さい"
                    maxValues={3}
                    onChange={(_e, value) => {
                      setValue('industryIds', value);
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name="businessScaleId"
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    select
                    label="運営店舗数を教えて下さい"
                    id="select-business-scale"
                    SelectProps={{
                      displayEmpty: true,
                      renderValue: (selected: string) => {
                        const found = businessScales.find(({ id }) => id === selected);
                        if (found) {
                          return found.name;
                        }
                        return '';
                      },
                    }}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message || '運営店舗数を選択してください' : null}
                    required={true}
                  >
                    <MenuItem value="">
                      <em>運営店舗数を選択してください</em>
                    </MenuItem>
                    {businessScales.map(({ id, name }) => (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </>
          )}
          <Controller
            control={control}
            name="email"
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                variant="outlined"
                type="email"
                fullWidth={true}
                required={true}
                error={!!errors.email}
                helperText={errors.email && 'メールアドレスを入力してください'}
                label="メールアドレス"
                size={size || 'medium'}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="password"
            rules={{ required: true, minLength: 8 }}
            render={({ field }) => (
              <SecretInput
                variant="outlined"
                fullWidth={true}
                required={true}
                error={!!errors.password}
                helperText={errors.password && '最低 8 文字のパスワードを入力してください'}
                label="パスワード"
                size={size || 'medium'}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name="agreement"
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} />}
                label={
                  <span style={{ fontSize: '0.8em', color: '#666' }}>
                    <Link href="/terms" target="_blank" color="#666">
                      利用規約
                    </Link>
                    ・
                    <Link href="https://kutikomi.com/privacy_policy/" target="_blank" color="#666">
                      プライバシーポリシー
                    </Link>
                    に同意
                  </span>
                }
              />
            )}
          />
          {errorMessage && (
            <Typography variant="body2" sx={{ color: 'error.main' }}>
              {errorMessage}
            </Typography>
          )}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size={size ? size : 'large'}
            sx={{ padding: '15px', fontWeight: 700 }}
          >
            {submitText ?? '無料で会員登録'}
          </Button>
          {/* <GoogleLoginForm label="会員登録" signType="signUp" agreement={watch('agreement')} size={size}/> */}
          <NextLink href={{ pathname: '/login', query: query }} passHref>
            <Link variant="body2" color="textSecondary" sx={{ textAlign: 'center' }}>
              ログインはこちら
            </Link>
          </NextLink>
        </Stack>
      </form>
    </Wrapper>
  );
};

export default SignupForm;

const Wrapper = styled.div`
  border-radius: 4px;
  display: flex;
  background: #fff;
  color: #000;
  padding: 24px;
  width: 100%;
`;
